.paper {
  margin: 4px;
  padding: 8px 0;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
  margin-top: 20px;
}

.tableContainer {
  position: relative;
  width: 100%;
}

.table {
  margin: 12px 0;
  overflow: auto;
  background-color: white;
  position: relative;
}

.table table {
  position: relative;
}

.tableTitle {
  padding-top: 7px;
  padding-left: 10px;
}

.table td {
  padding: 0px;
  text-align: center !important;
}

.table th {
  background-color: #696969;
  color: white;
  padding: 0.3em 8px;
  text-align: center;
}

.table th:not(:last-child) {
  border-right: 1px solid lightgrey !important;
}

.table td:not(:last-child) {
  border-right: 1px solid lightgrey !important;
}

.table .headCell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.filters th {
  position: relative;
  z-index: 1;
  background-color: #fafafa;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
}

.preloader {
  display: flex;
  justify-content: center;
  flex: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255,255,255,.7);
}

.preloaderAnimated {

}

.preloaderAnimated:after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100px;
  background: linear-gradient(90deg, transparent, white, transparent);
  rotate: 10deg;
  animation-name: glow-background;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes glow-background {
  0% {
    left: -30%;
  }
  100% {
    left: 130%;
  }
}

@media (max-width: 768px) {
  .table td,
  .table th {
    font-size: 10px !important;
  }
}
