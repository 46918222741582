.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
}

.createBot {
  cursor: pointer;
  transition: all 0.2s;
}

.createBot:hover {
  color: #696969;
}

.paper {
  margin: 4px;
  padding: 8px 0;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.graph {
  margin: 4px;
  padding: 8px 0;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
  position: relative;
  z-index: 9999 !important;
  padding: 20px 4px;
  width: 1000px;
}

.rangeButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0 10px;
}

@media (max-width: 1024px) {
  .graph {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .paper {
    margin: 4px 0;
  }
}
