.headerContainer {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;
  box-sizing: border-box;
  background-color: white;
  font-weight: 500;
  margin: 10px 0;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  margin: 0;
  min-height: 48px;
}

.user {
  flex-shrink: 0;
  margin-right: 0;
  margin-left: auto;
}

.navTabs {
  flex: 1 1 auto;
}

.greeting {
  margin-right: 18px;
}

@media (max-width: 767px) {
  .greeting {
    display: none;
  }
}

.dropdownContainer {
  position: relative;
  min-height: 48px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.dropdown {
  position: absolute;
  background: #fff;
  display: none;
  top: 48px;
  left: 0;
  box-shadow: 4px 4px 4px 0px rgba(34, 60, 80, 0.2);
  min-width: 200px;
  max-height: calc(100vh - 48px);
  overflow: scroll;
  padding-bottom: 100px;
}

.dropdownTitle {
  margin-left: 10px !important;
  display: block;
}

.dropdownItem {
  font-size: 30px;
  padding: 25px 35px;
  white-space: nowrap;
  color: #696969 !important;
}
