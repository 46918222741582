.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 20px;
}

.form button {
  max-width: 110px;
}

.input {
  width: 260px;
  margin-bottom: 18px !important;
}

.form h5 {
  margin-top: 12px;
}
