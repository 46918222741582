.announcements {
  padding: 16px;
  background: rgba(231, 226, 226, 0.8);
  margin: 4px;
  border-radius: 8px;
}

.announcementsEmpty {
  background: rgba(18, 139, 18, .8);
  color: white;
}

.announcementsTitle {
  font-size: 18px;
  margin-bottom: 16px;

  display: block;
  font-weight: 600;
}

.announcement {
  display: flex;
  gap: 4px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  font-weight: 500;
}

.announcementLink {
  text-decoration: underline;
}
