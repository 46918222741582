.root {
  margin: 4px;
}

.heading {
  display: flex;
  flex: 1;
  height: 50px;
  gap: 12px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: #788896;
  border-radius: 4px;
}
.title h5 {
  color: #fff;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease-in 0.2s;
  height: 100%;
  width: 142px;
  background-color: #c3cfd9;
  color: #44535f;
  border-radius: 4px;
}

.button:hover {
  background-color: '#788896'
}