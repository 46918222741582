.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.settings {
  position: relative;
  z-index: 9999 !important;
  padding: 16px 12px;
  max-width: 700px;
}

.saveBtn {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.inputLabel {
  position: static !important;
  transform: none !important;
  font-size: 12px !important;
}

.closeButton {
  cursor: pointer;
  transition: all 0.2s;
}

.closeButton:hover {
  color: #696969;
}

.input {
  max-width: 150px;
  min-width: 120px;
}

.inputSelect {
  max-width: 150px;
  min-width: 120px;
}

.tabButtons {
  margin-bottom: 10px;
}

.tripleEditorWrapper {
    min-width: 500px;
}

.tripleBaseParams {
  margin: 20px 0;
}

.tripleBaseParamsColumn {
  width: 100%;
  padding-right: 20px;
  display: flex;
  justify-content: space-around;
}

.tripleBaseParamsColumn > div:not(:last-child) {
  margin-right: 8px;
}

.tripleStepParams {
  display: flex;
}

.tripleStepParams h5 {
  margin-bottom: 15px;
}

.tripleStepParamsRow {
  display: flex;
  flex-direction: column;
  width: 33.33%;
}

@media (max-width: 768px) {
  .tripleStepParams {
    flex-direction: column;
  }

  .tripleStepParamsRow {
    width: 100%;
  }

  .tripleEditorWrapper {
    min-width: auto;
  }

  .settings {
    max-height: 80vh;
    overflow: auto;
  }

  .input,.inputSelect {
    width: 100%;
    max-width: 100%;
  }

  .inputSelect > div {
    max-width: 100%;
  }
}
