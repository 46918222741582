.burgerBtn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  cursor: pointer;

  text-transform: uppercase;
  font-size: 16px;
  color: #696969;
  font-weight: bold;
}

.burger {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 10px;
}

.burger div {
  transition: all 0.3s linear;
  width: 100%;
  border-radius: 2px;
  height: 3px;
  background-color: #696969;
  transform-origin: 1px;
}
