.paper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 4px;
  padding: 8px 10px 30px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
  margin-top: 20px;
}

.navItem,
.activeNavItem {
  display: table-cell;
  position: relative;
  margin: 0 10px;
  color: #696969;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.14em;
  display: inline-block;
  padding: 6px 10px;
}

.navItem:after,
.activeNavItem:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: '';
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: #696969;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.navItem:hover:after {
  width: 100%;
  left: 0;
}

.activeNavItem:after {
  width: 100%;
  left: 0;
}