.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app:fullscreen,
.app:-webkit-full-screen {
	width: 100%;
	height: 100%;
	background-color: #f5f6fa;
  overflow: auto;
  font-size: 14px;
}

.app:fullscreen td,
.app:-webkit-full-screen td,
.app:fullscreen th,
.app:-webkit-full-screen th {
	font-size: 10px !important;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 24px;
}

.input {
  width: 350px;
  margin: 12px 9px !important;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.red {
  color: #e00000;
  font-weight: 600;
}

.green {
  color: #128b12;
  font-weight: 600;
}

.blue {
  color: #1976d2;
  font-weight: 600;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column {
  display: flex;
  max-width: 100%;
  flex-direction: column;
}

.flex-row {
  display: flex;
  max-width: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

@media (max-width: 1000px) {
  .MuiContainer-root {
    padding: 0 !important;
  }
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.break-all {
  word-break: break-all;
}

.width-40 {
  width: 40px;
}
