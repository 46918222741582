.paper {
  margin: 4px;
  padding: 8px 0;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.54) !important;
  border: 1px solid lightgrey;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
  align-items: center;
  width: 100%;
}
